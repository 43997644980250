import { ReviewCreateInput } from "~/types/type.generated"

export const sexSelect = [
  {
    id: "male",
    name: "男性",
  },
  {
    id: "female",
    name: "女性",
  },
  {
    id: "others",
    name: "その他",
  },
]

export const ageSelect = [
  {
    id: 1,
    name: "10~19歳",
    nameEn: "Ages 10~19",
  },
  {
    id: 2,
    name: "20~29歳",
    nameEn: "Ages 20~29",
  },
  {
    id: 3,
    name: "30~39歳",
    nameEn: "Ages 30~39",
  },
  {
    id: 4,
    name: "40~49歳",
    nameEn: "Ages 40~49",
  },
  {
    id: 5,
    name: "50~59歳",
    nameEn: "Ages 50~59",
  },
  {
    id: 6,
    name: "60歳以上",
    nameEn: "60 and over",
  },
]

export const usabilitySelect = [
  {
    id: 1,
    name: "小さい",
    nameEn: "Small",
  },
  {
    id: 2,
    name: "少し小さい",
    nameEn: "Slightly Small",
  },
  {
    id: 3,
    name: "ちょうど良い",
    nameEn: "Just Right",
  },
  {
    id: 4,
    name: "少し大きい",
    nameEn: "Slightly Large",
  },
  {
    id: 5,
    name: "大きい",
    nameEn: "Large",
  },
]

export const starSelect = [
  {
    num: 1,
    name: "★☆☆☆☆",
  },
  {
    num: 2,
    name: "★★☆☆☆",
  },
  {
    num: 3,
    name: "★★★☆☆",
  },
  {
    num: 4,
    name: "★★★★☆",
  },
  {
    num: 5,
    name: "★★★★★",
  },
]

export const heightFilterSelect = [
  {
    gte: 0,
    lte: 140,
    key: "140cm以下",
    value: "lte140",
  },
  {
    gte: 140,
    lte: 150,
    key: "140~150cm",
    value: "140-150",
  },
  {
    gte: 150,
    lte: 155,
    key: "150~155cm",
    value: "150-155",
  },
  {
    gte: 155,
    lte: 160,
    key: "155~160cm",
    value: "155-160",
  },
  {
    gte: 160,
    lte: 165,
    key: "160~165cm",
    value: "160-165",
  },
  {
    gte: 165,
    lte: 170,
    key: "165~170cm",
    value: "165-170",
  },
  {
    gte: 170,
    lte: 175,
    key: "170~175cm",
    value: "170-175",
  },
  {
    gte: 175,
    lte: 180,
    key: "175~180cm",
    value: "175-180",
  },
  {
    gte: 180,
    lte: 190,
    key: "180~190cm",
    value: "180-190",
  },
  {
    gte: 190,
    lte: 300,
    key: "190cm以上",
    value: "gte190",
  },
]

export const weightFilterSelect = [
  {
    gte: 0,
    lte: 40,
    key: "40kg以下",
    value: "lte40",
  },
  {
    gte: 40,
    lte: 45,
    key: "40~45kg",
    value: "40-45",
  },
  {
    gte: 45,
    lte: 50,
    key: "45~50kg",
    value: "45-50",
  },
  {
    gte: 50,
    lte: 55,
    key: "50~55kg",
    value: "50-55",
  },
  {
    gte: 55,
    lte: 60,
    key: "55~60kg",
    value: "55-60",
  },
  {
    gte: 60,
    lte: 65,
    key: "60~65kg",
    value: "60-65",
  },
  {
    gte: 65,
    lte: 70,
    key: "65~70kg",
    value: "65-70",
  },
  {
    gte: 70,
    lte: 75,
    key: "70~75kg",
    value: "70-75",
  },
  {
    gte: 75,
    lte: 80,
    key: "75~80kg",
    value: "75-80",
  },
  {
    gte: 80,
    lte: 85,
    key: "80~85kg",
    value: "80-85",
  },
  {
    gte: 85,
    lte: 90,
    key: "85~90kg",
    value: "85-90",
  },
  {
    gte: 90,
    lte: 200,
    key: "90kg以上",
    value: "gte90",
  },
]

export const footSizeFilterSelect = [
  {
    gte: 0,
    lte: 22,
    key: "22cm以下",
    value: "lte22",
  },
  {
    gte: 22,
    lte: 23,
    key: "22~23cm",
    value: "22-23",
  },
  {
    gte: 23,
    lte: 24,
    key: "23~24cm",
    value: "23-24",
  },
  {
    gte: 24,
    lte: 25,
    key: "24~25cm",
    value: "24-25",
  },
  {
    gte: 25,
    lte: 26,
    key: "25~26cm",
    value: "25-26",
  },
  {
    gte: 26,
    lte: 27,
    key: "26~27cm",
    value: "26-27",
  },
  {
    gte: 27,
    lte: 28,
    key: "27~28cm",
    value: "27-28",
  },
  {
    gte: 28.5,
    lte: 29,
    key: "28~29cm",
    value: "28-29",
  },
  {
    gte: 29,
    lte: 30,
    key: "29~30cm",
    value: "29-30",
  },
  {
    gte: 30,
    lte: 40,
    key: "30cm以上",
    value: "gte30",
  },
]

export const FOOT_CATEGORY_SLUGS = ["insole", "sandal", "socks"] as const

export const initReviewFormData = (): {
  review: ReviewCreateInput
  confirm: boolean
  isAnonymous: boolean
  recordId?: string
} => {
  return {
    review: {
      product_id: "",
      star: 0,
      title: "",
      body: "",
      usability: 0,
      sku_id: "",
      size: "",
      name: "",
      user_id: "",
      sex: "",
      age: 0,
      height: undefined,
      weight: undefined,
      foot: "NULL",
    },
    confirm: false,
    isAnonymous: false,
    recordId: undefined,
  }
}
