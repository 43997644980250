import { NREUM } from "~/types/newrelic"

const MAX_WAIT_TIME = 3000

export const waitForNREUM = (): Promise<NREUM> => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now()
    const interval = setInterval(() => {
      if (window.NREUM !== undefined) {
        clearInterval(interval)
        resolve(window.NREUM)
      } else if (Date.now() - startTime >= MAX_WAIT_TIME) {
        clearInterval(interval)
        reject(new Error("Timeout: window.NREUM not found within the specified time."))
      }
    }, 100)
  })
}
