import { OrderAddressInfo, OrderForm } from "~/types/order"

export const initOrderFormData: OrderForm = {
  user_info: {
    name: "",
    name_kana: "",
    zip_code: "",
    prefecture: "",
    address_line1: "",
    address_line2: "",
    tel: "",
  },
  address_info: {
    name: "",
    name_kana: "",
    zip_code: "",
    prefecture: "",
    address_line1: "",
    address_line2: "",
    tel: "",
  },
  receive_mail: "all",
  coupon_code: "",
  email: "",
  refferal_query: {
    ad_url: "",
    to_url: "",
  },
  traffic_query: {
    full_path: "",
  },
  shipping_info: {
    shipping_referred_type: "standard_delivery",
    shipping_referred_date: "",
    shipping_referred_time: "",
    scheduled_shipping_date: "",
    delivery_placement: "",
  },
  original_gifts: [],
  social_id: "",
  amazonPayCard: {
    brand: "",
    number: "",
  },
  local_user_id: "",
  current_rank: "regular",
} as const

export const initOrderAddressError: OrderAddressInfo = {
  name: "",
  name_kana: "",
  zip_code: "",
  prefecture: "",
  address_line1: "",
  address_line2: "",
  tel: "",
} as const
