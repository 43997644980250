// @ts-nocheck
import { createClient, cacheExchange, ssrExchange, fetchExchange, Client } from "@urql/vue"
import { uncrunch } from "graphql-crunch"
import { pipe, map } from "wonka"
import useCookieState from "~/composables/useCookieState"

const ssrKey = "__URQL_DATA__"

const transformExchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      forward,
      // Sample transform code
      map((result) => {
        const { data } = result
        if (!data || (!data.publicPageOne && !data.publicPagePartsMany)) return result

        // page parts uncrunch
        if (data.publicPagePartsMany && data.publicPagePartsMany.crunch) {
          const rjson = uncrunch(JSON.parse(data.publicPagePartsMany.crunch))
          result.data.publicPagePartsMany.items = rjson
        }
        return result
      }),
    )
}

export default defineNuxtPlugin((nuxt) => {
  const config = useRuntimeConfig()
  const { getAccessToken } = useCookieState()
  const { getSiteId } = useSite()
  const graphqlEndpoint = config.public.GRAPHQL_ENDPOINT

  const { vueApp } = nuxt

  const isServerSide = typeof window === "undefined"

  const ssr = ssrExchange({
    isClient: process.client,
    initialState: !isServerSide ? nuxt.payload[ssrKey] : undefined,
  })

  // when app is created in browser, restore SSR state from nuxt payload
  if (process.client) {
    nuxt.hook("app:created", () => {
      ssr.restoreData(nuxt.payload[ssrKey])
    })
  }

  // when app has rendered in server, send SSR state to client
  // if (process.server) {
  //   nuxt.hook("app:rendered", () => {
  //     nuxt.payload[ssrKey] = ssr.extractData()
  //   })
  // }

  // use urql graphCache
  // const cacheConfig: GraphCacheConfig = {
  //   schema,
  //   keys: {
  //     Country: (data) => data.code || null
  //   },
  //   resolvers: {
  //     Query: {
  //       country: (_, args) => ({__typename: "Country", code: args.code})
  //     }
  //   }
  //   // storage: process.client ? makeDefaultStorage() : undefined
  // }
  // const cache = graphCacheExchange(cacheConfig)

  const client = createClient({
    url: graphqlEndpoint,
    exchanges: [
      transformExchange,
      cacheExchange,
      ssr,
      // persistedExchange({
      //   preferGetForPersistedQueries: true,
      // }),
      fetchExchange,
    ],
    fetchOptions: () => {
      const token = getAccessToken()
      const siteId = getSiteId()

      return {
        headers: {
          "x-graphql-client": "urql", // used in basic-auth
          custom_authorization: token ? `Bearer ${token}` : "",
          site_id: siteId,
        },
      }
    },
  })

  nuxt.provide("urql", client)
  vueApp.provide("$urql", ref(client))
})

declare module "#app" {
  interface NuxtApp {
    $urql: Ref<Client>
  }
}
