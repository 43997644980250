import { defineToast } from "~/state"
// import { defineFeatureFlagProvider } from "~/state/featureFlag"
import { defineCart } from "~/composables/useCart"
// import { useStore } from "~~/stores"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("vue:setup", () => {
    defineToast()
    defineCart()
    // const route = useRoute()
    // const store = useStore()

    // watch(
    //   () => route.value.path,
    //   (value) => {
    //     const excludePathRegex = [/^[\w/-]*\/order\/new$/]
    //     const isExcludePath = excludePathRegex.some((regex) => regex.test(value))
    //     if (!isExcludePath) {
    //       store.dispatch("initializeCartList")
    //       if (store.getters.socialId) store.dispatch("initializeSocialId")
    //     }
    //   },
    // )

    // const switchFalseEveryFeatureFlag = route.value.query.switchFalseEveryFeatureFlag
    // if (switchFalseEveryFeatureFlag) store.dispatch("switchFalseEveryFeatureFlag")

    // defineFeatureFlagProvider()

    // onMounted(async () => {
    //   const userProvider = useUser()
    //   if (store.getters.isSigned) {
    //     const siteId = store.getters.site?.site_id
    //     const resFetchUser = await userProvider.ownFetchUserOne(siteId)
    //     if (resFetchUser.status !== 200) await userProvider.signOut()
    //   }
    // })
  })
})
