export const loadScript = (src: string, id: string, type?: string, async?: boolean): void => {
  if (document.getElementById(id)) return
  const script = document.createElement("script")
  script.src = src
  if (id) {
    script.id = id
  }
  if (type) {
    script.type = type
  }
  if (async) {
    script.async = async
  }
  if (!document.getElementById(id)) {
    document.head.appendChild(script)
  }
}

export const removeScript = (id: string): void => {
  const script = document.getElementById(id)
  if (script) {
    script.remove()
  }
}
