const toastedKey = "isToastedStore"
interface ToastedInfo {
  isToasted: boolean
  timerId: ReturnType<typeof setTimeout> | undefined
  className: string
  message: string
}

export const isToastedState = () => {
  const toastedReactive: ToastedInfo = reactive({
    isToasted: false,
    timerId: undefined,
    className: "",
    message: "",
  })

  const showSuccessToasted = (_message: string): void => {
    toastedReactive.className = "_success"
    commonShowToasted(_message)
  }

  /**
   * エラー用トースト（背景赤）を表示する
   * @param _message メッセージ
   * @param showMs 表示する時間(ms) デフォルトは3000ms
   */
  const showErrorToasted = (_message: string, showMs = 3000): void => {
    toastedReactive.className = "_error"
    commonShowToasted(_message, showMs)
  }
  const showWarnedToasted = (_message: string): void => {
    toastedReactive.className = "_warning"
    commonShowToasted(_message)
  }

  /**
   * 共通のトースト表示処理
   * @param _message メッセージ
   * @param showMs 表示する時間(ms) デフォルトは3000ms
   */
  const commonShowToasted = (_message: string, showMs = 3000): void => {
    if (toastedReactive.timerId) clearTimeout(toastedReactive.timerId)
    toastedReactive.message = _message
    toastedReactive.isToasted = true
    toastedReactive.timerId = setTimeout(() => {
      toastedReactive.isToasted = false
    }, showMs)
  }

  return {
    toastedReactive,
    showSuccessToasted,
    showErrorToasted,
    showWarnedToasted,
  }
}
export type ToastedStateType = ReturnType<typeof isToastedState>
export const ToastedStateKey: InjectionKey<ToastedStateType> = Symbol(toastedKey)

const cartKey = "isCartStore"
interface CartInfo {
  isClass: boolean
  showCart: boolean
  scrollPosition: number
}

export const handleStatusCartState = () => {
  const cartReactive: CartInfo = reactive({
    isClass: false,
    showCart: false,
    scrollPosition: 0,
  })

  const handleStatusCart = (): void => {
    cartReactive.isClass = !cartReactive.isClass
    if (cartReactive.showCart) {
      setTimeout(() => {
        cartReactive.showCart = false
        disableBodyScroll(cartReactive.showCart)
      }, 250)
    } else {
      cartReactive.showCart = true
      disableBodyScroll(cartReactive.showCart)
    }
  }

  const handleCloseCart = (): void => {
    if (cartReactive.showCart) {
      setTimeout(() => {
        cartReactive.isClass = false
        cartReactive.showCart = false
        disableBodyScroll(cartReactive.showCart)
      }, 250)
    }
  }

  const disableBodyScroll = (showCart: boolean): void => {
    if (showCart) {
      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      })
      document.addEventListener("wheel", handleTouchMove, { passive: false })
    } else {
      document.removeEventListener("touchmove", handleTouchMove)
      document.removeEventListener("wheel", handleTouchMove)
    }
  }

  const handleTouchMove = (event: any): void => {
    const overlay = document.getElementById("side-cart-overlay")
    if (event.target.closest("#common-cart") && event.target !== overlay) {
      event.stopPropagation()
    } else {
      event.preventDefault()
    }
  }

  return {
    cartReactive,
    handleStatusCart,
    handleCloseCart,
  }
}
export type CartStateType = ReturnType<typeof handleStatusCartState>
export const CartStateKey: InjectionKey<CartStateType> = Symbol(cartKey)

const restockNoticeKey = "isRestockNoticeStore"
interface RestockNoticeModalInfo {
  entryModalOpen: boolean
  doneModalOpen: boolean
}

export const restockNoticeModalState = () => {
  const restockNoticeModalReactive: RestockNoticeModalInfo = reactive({
    entryModalOpen: false,
    doneModalOpen: false,
  })

  const entryModalHandler = (): void => {
    restockNoticeModalReactive.entryModalOpen = !restockNoticeModalReactive.entryModalOpen
  }

  const doneModalHandler = (): void => {
    restockNoticeModalReactive.doneModalOpen = !restockNoticeModalReactive.doneModalOpen
  }

  return {
    restockNoticeModalReactive,
    entryModalHandler,
    doneModalHandler,
  }
}
export type RestockNoticeModalStateType = ReturnType<typeof restockNoticeModalState>
export const RestockNoticeModalStateKey: InjectionKey<RestockNoticeModalStateType> = Symbol(restockNoticeKey)
