import { defineStore } from "pinia"
export const useConfirmDialogStore = defineStore("confirmDialog", () => {
  const isShow = ref(false)
  const isAgree = ref(false)
  const message = ref("")
  const show = (_message: string) => {
    isShow.value = true
    isAgree.value = false
    message.value = _message
  }
  const hide = () => {
    isAgree.value = false
    isShow.value = false
  }

  const agree = () => {
    isAgree.value = true
    isShow.value = false
  }

  return { isShow, isAgree, message, show, hide, agree }
})
