/**
 * try-catchでcatchされた値をErrorに変換する。
 * 注: JSの仕様上Error以外の値もthrowが可能なためerrorはunknownとしてアノテーションされる
 */
export const catchGuard = (error: unknown): Error => {
  if (error instanceof Error) {
    return error
  }
  return new Error(String(error))
}
