import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/apps/consumer/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "order-new": () => import("/app/apps/consumer/middleware/order-new.ts")
}