<template>
  <img
    :src="isError ? '/images/common/no-image.png' : convertImagePath(env, src, quality, width, height)"
    :alt="alt"
    :width="width"
    :height="height"
    :aria-label="ariaLabel ? ariaLabel : undefined"
    :loading="lazy ? 'lazy' : 'eager'"
    :onerror="errorImage"
    :fetchpriority="priority"
    :class="{ [$style.copy_guard]: alt === COPY_GUARD_ALT }"
    decoding="async"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { convertImagePath } from "@tential/ec-gql-schema/utils/img"
import { useRuntimeConfig } from "nuxt/app"

/**
 * [Nuxt Image](https://image.nuxt.com/)の仕様まとめ(2023/09/08現在)
 * - srcに空文字列を渡すとエラー
 * - widthおよびheightに小数を渡すとエラー
 * - formatの値によらずsvgはsvgに変換される
 */

const config = useRuntimeConfig()

const env = (config?.public?.APP_ENV as string) || process.env.APP_ENV || "development"

const isError = ref<boolean>(false)

const COPY_GUARD_ALT = "BAKUNE_CM"

type Props = {
  src: string
  alt?: string
  width?: number
  height?: number
  /** 画像の品質(0.0 ~ 1.0) */
  quality?: number
  lazy?: boolean
  ariaLabel?: string
  /** 読込中画像を表示するか */
  isShowPlaceholder?: boolean

  priority?: "high" | "low" | "auto"
}

const props = withDefaults(defineProps<Props>(), {
  alt: "",
  width: undefined,
  height: undefined,
  quality: 0.7,
  lazy: true,
  ariaLabel: "",
  isShowPlaceholder: true,
  priority: "auto",
})

const errorImage = () => {
  isError.value = true
}

watch(
  () => props.src,
  () => {
    isError.value = false
  },
)
</script>
<style module scoped lang="scss">
.copy_guard {
  pointer-events: none;
}
</style>
