import { CookieOptions, CookieRef } from "nuxt/app"
import { WatchCallback } from "nuxt/dist/app/compat/capi"
import { AuthCookie } from "~/types/cookie"
import { COOKIE_KEY } from "~/const/cookie"

/**
 * cookieからリアクティブな値を取れるようにする関数
 * https://github.com/nuxt/nuxt/pull/20970 こちらで解決されているので、Nuxt3.6以上になれば不要になる認識
 */
const useStatefulCookie = <T = string | null>(name: string, options?: CookieOptions<T>): CookieRef<T> => {
  const cookie = useCookie(name, options)
  const state = useState(name, () => cookie.value)

  const callback: WatchCallback<T, T> = () => {
    cookie.value = state.value
  }

  watch(state, callback, { deep: true })

  return state
}

export default () => {
  const config = useRuntimeConfig()
  const appEnv = config.public.APP_ENV || "development"
  const isDevelopment = ["development"].includes(appEnv)
  const cookie = useStatefulCookie<AuthCookie | null>(COOKIE_KEY.AUTH, {
    path: "/",
    maxAge: 60 * 60 * 24 * 7, // 7 days
    sameSite: "lax",
    secure: !isDevelopment,
  })

  const setSignInCookie = (accessToken?: string, refreshToken?: string) => {
    cookie.value = {
      ...cookie.value,
      accessToken,
      refreshToken,
    }
  }

  const signOutCookie = () => {
    cookie.value = null
  }

  const getAccessToken = () => {
    return cookie.value?.accessToken || undefined
  }

  const getRefreshToken = () => {
    return cookie.value?.refreshToken || undefined
  }

  return { getAccessToken, setSignInCookie, signOutCookie, getRefreshToken }
}
