<template>
  <div :class="$style.loading_wrap">
    <div :class="$style.loading">
      <AtomsConsumerImage src="/images/common/loading.svg" alt="Loading" :class="$style.loading_img" />
      <slot />
    </div>
  </div>
</template>

<style module scoped lang="scss">
.loading_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .loading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .loading_img {
      width: 3rem;
      animation: flip ease-in-out infinite 3s 0.3s;

      @include md {
        width: 6rem;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0);
        }
        20% {
          transform: rotateY(180deg);
        }
        40% {
          transform: rotateY(0);
        }
      }
    }
  }
}
</style>
