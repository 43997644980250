/**
 * sites に関する情報を取得・返却する
 */
export const useSite = () => {
  const config = useRuntimeConfig()
  const isProduction = config.public.APP_ENV === "production"
  const SITE_SLUG = "tential"

  const fetchSiteFindOne = async () => {
    const { data, error } = await useAsyncData("siteFindOne", async () => {
      return await $fetch("/api/site/find_one", {
        method: "GET",
        headers: {
          // basic-auth対策
          "x-graphql-client": "urql",
        },
        // 失敗した際のリトライ回数
        retry: 3,
      })
    })

    if (error.value) {
      throw createError(error.value)
    }

    if (!data.value) {
      throw createError("siteFindOne data is null")
    }

    return data.value
  }

  return {
    SITE_SLUG,
    getSiteId: () => (isProduction ? "62eb44bee55e936f03302305" : "64dc975643b15704f3251d82"),
    fetchSiteFindOne,
  }
}
