export const RenewalRedirectUrls: { [key: string]: string } = {
  "/foot-wear/foot-accessory/shoe-spray": "/day-conditioning/work-support-accessory/shoe-spray",
  "/foot-wear/insole-standard/hk": "/day-conditioning/insole-standard/hk",
  "/foot-wear/insole-standard/tw": "/day-conditioning/insole-standard/tw",
  "/foot-wear/insole-standard/us": "/day-conditioning/insole-standard/us",
  "/foot-wear/insole/insole-business": "/day-conditioning/insole/insole-business",
  "/foot-wear/insole/insole-business-lite": "/day-conditioning/insole/insole-business-lite",
  "/foot-wear/insole/insole-golf": "/day-conditioning/insole/insole-golf",
  "/foot-wear/insole/insole-golf-carbon": "/day-conditioning/insole/insole-golf-carbon",
  "/foot-wear/insole/insole-golf-lite": "/day-conditioning/insole/insole-golf-lite",
  "/foot-wear/insole/insole-junior": "/day-conditioning/insole/insole-junior",
  "/foot-wear/insole/insole-lite": "/day-conditioning/insole/insole-lite",
  "/foot-wear/insole/insole-running": "/day-conditioning/insole/insole-running",
  "/foot-wear/insole/insole-standard": "/day-conditioning/insole/insole-standard",
  "/foot-wear/insole/ladies-insole-produced-by-ogatore": "/day-conditioning/insole/ladies-insole-produced-by-ogatore",
  "/foot-wear/sandal/recovery-sandal-warm": "/day-conditioning/sandal/recovery-sandal-warm",
  "/foot-wear/sandal/recoverysandal-conditioning-flipflop":
    "/day-conditioning/sandal/recoverysandal-conditioning-flipflop",
  "/foot-wear/sandal/recoverysandal-conditioning-flipflop-copy":
    "/day-conditioning/sandal/recoverysandal-conditioning-flipflop-copy",
  "/foot-wear/sandal/recoverysandal-conditioning-slide": "/day-conditioning/sandal/recoverysandal-conditioning-slide",
  "/foot-wear/sandal/recoverysandal-original-shoe-case": "/day-conditioning/sandal/recoverysandal-original-shoe-case",
  "/foot-wear/sandal/recoverysandal-relax-flipflop": "/day-conditioning/sandal/recoverysandal-relax-flipflop",
  "/foot-wear/sandal/recoverysandal-relax-slide": "/day-conditioning/sandal/recoverysandal-relax-slide",
  "/foot-wear/sandal/recoverysandal-relax-slide-yahoo": "/day-conditioning/sandal/recoverysandal-relax-slide-yahoo",
  "/foot-wear/sandal/recoverysandal-relax-winter": "/day-conditioning/sandal/recovery-sandal-warm",
  "/foot-wear/sandal/recoverysandal-relax-winter-laundry-net":
    "/day-conditioning/sandal/recoverysandal-relax-winter-laundry-net",
  "/foot-wear/socks/business-socks": "/day-conditioning/socks/business-socks",
  "/foot-wear/socks/golf-socks": "/day-conditioning/socks/golf-socks",
  "/sleep/baby/bakune-baby-blanket": "/night-conditioning/baby/bakune-baby-blanket",
  "/sleep/baby/bakune-baby-sleeper": "/night-conditioning/baby/bakune-baby-sleeper",
  "/sleep/bath/bakune-bath": "/night-conditioning/bath/bakune-bath",
  "/sleep/bath/totonou-bathsalt": "/night-conditioning/bath/totonou-bathsalt",
  "/sleep/bedding/bakune-comforter": "/night-conditioning/bedding/bakune-comforter",
  "/sleep/bedding/bakune-duvet-cover": "/night-conditioning/bedding/bakune-duvet-cover",
  "/sleep/bedding/bakune-recovery-mattress": "/night-conditioning/bedding/bakune-recovery-mattress",
  "/sleep/bedding/bakune-sheets": "/night-conditioning/bedding/bakune-sheets",
  "/sleep/giftset/giftset-bakune-accessory": "/night-conditioning/giftset/giftset-bakune-accessory",
  "/sleep/giftset/giftset-bakune-eyemask": "/night-conditioning/giftset/giftset-bakune-eyemask",
  "/sleep/giftset/giftset-bakune-ladies-flarepants-eyemask":
    "/night-conditioning/giftset/giftset-bakune-ladies-flarepants-eyemask",
  "/sleep/giftset/giftset-bakune-ladies-joggerpants-eyemask":
    "/night-conditioning/giftset/giftset-bakune-ladies-joggerpants-eyemask",
  "/sleep/giftset/giftset-eyemask-sleepsocks": "/night-conditioning/giftset/giftset-eyemask-sleepsocks",
  "/sleep/pillow/bakune-hug": "/night-conditioning/pillow/bakune-hug",
  "/sleep/pillow/bakune-makura": "/night-conditioning/pillow/bakune-makura",
  "/sleep/pillow/bakune-makura-cover": "/night-conditioning/pillow/bakune-makura-cover",
  "/sleep/recovery-wear/bakume-pajamas-short-pants-set":
    "/night-conditioning/recovery-wear/bakume-pajamas-short-pants-set",
  "/sleep/recovery-wear/bakune-dry-ladies-long-pants": "/night-conditioning/recovery-wear/bakune-dry-ladies-long-pants",
  "/sleep/recovery-wear/bakune-dry-ladies-long-set": "/night-conditioning/recovery-wear/bakune-dry-ladies-long-set",
  "/sleep/recovery-wear/bakune-dry-ladies-long-t-shirt":
    "/night-conditioning/recovery-wear/bakune-dry-ladies-long-t-shirt",
  "/sleep/recovery-wear/bakune-dry-ladies-set": "/night-conditioning/recovery-wear/bakune-dry-ladies-set",
  "/sleep/recovery-wear/bakune-dry-ladies-short-pants":
    "/night-conditioning/recovery-wear/bakune-dry-ladies-short-pants",
  "/sleep/recovery-wear/bakune-dry-ladies-t-shirt": "/night-conditioning/recovery-wear/bakune-dry-ladies-t-shirt",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-long-pants":
    "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-long-pants",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-long-set":
    "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-long-set",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-long-t-shirt":
    "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-long-t-shirt",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-set": "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-set",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-short-pants":
    "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-short-pants",
  "/sleep/recovery-wear/bakune-dry-ladies23ss-t-shirt":
    "/night-conditioning/recovery-wear/bakune-dry-ladies23ss-t-shirt",
  "/sleep/recovery-wear/bakune-dry-long-regular-pants":
    "/night-conditioning/recovery-wear/bakune-dry-long-regular-pants",
  "/sleep/recovery-wear/bakune-dry-long-regularpants-set":
    "/night-conditioning/recovery-wear/bakune-dry-long-regularpants-set",
  "/sleep/recovery-wear/bakune-dry-long-regularpants-set/us":
    "/night-conditioning/recovery-wear/bakune-dry-long-regularpants-set/us",
  "/sleep/recovery-wear/bakune-dry-long-slim-pants": "/night-conditioning/recovery-wear/bakune-dry-long-slim-pants",
  "/sleep/recovery-wear/bakune-dry-long-slimpants-set":
    "/night-conditioning/recovery-wear/bakune-dry-long-slimpants-set",
  "/sleep/recovery-wear/bakune-dry-long-straightpants-set":
    "/night-conditioning/recovery-wear/bakune-dry-long-straightpants-set",
  "/sleep/recovery-wear/bakune-dry-long-t-shirt": "/night-conditioning/recovery-wear/bakune-dry-long-t-shirt",
  "/sleep/recovery-wear/bakune-dry-one-piece": "/night-conditioning/recovery-wear/bakune-dry-one-piece",
  "/sleep/recovery-wear/bakune-dry-pants": "/night-conditioning/recovery-wear/bakune-dry-pants",
  "/sleep/recovery-wear/bakune-dry-set": "/night-conditioning/recovery-wear/bakune-dry-set",
  "/sleep/recovery-wear/bakune-dry-set/us": "/night-conditioning/recovery-wear/bakune-dry-set/us",
  "/sleep/recovery-wear/bakune-dry-t-shirt": "/night-conditioning/recovery-wear/bakune-dry-t-shirt",
  "/sleep/recovery-wear/bakune-dry23ss-long-regular-pants":
    "/night-conditioning/recovery-wear/bakune-dry23ss-long-regular-pants",
  "/sleep/recovery-wear/bakune-dry23ss-long-regularpants-set":
    "/night-conditioning/recovery-wear/bakune-dry23ss-long-regularpants-set",
  "/sleep/recovery-wear/bakune-dry23ss-long-t-shirt": "/night-conditioning/recovery-wear/bakune-dry23ss-long-t-shirt",
  "/sleep/recovery-wear/bakune-dry23ss-pants": "/night-conditioning/recovery-wear/bakune-dry23ss-pants",
  "/sleep/recovery-wear/bakune-dry23ss-set": "/night-conditioning/recovery-wear/bakune-dry23ss-set",
  "/sleep/recovery-wear/bakune-dry23ss-t-shirt": "/night-conditioning/recovery-wear/bakune-dry23ss-t-shirt",
  "/sleep/recovery-wear/bakune-dry23ss-tunic": "/night-conditioning/recovery-wear/bakune-dry23ss-tunic",
  "/sleep/recovery-wear/bakune-ladies-flare-pants": "/night-conditioning/recovery-wear/bakune-ladies-flare-pants",
  "/sleep/recovery-wear/bakune-ladies-flarepants-set": "/night-conditioning/recovery-wear/bakune-ladies-flarepants-set",
  "/sleep/recovery-wear/bakune-ladies-jogger-pants": "/night-conditioning/recovery-wear/bakune-ladies-jogger-pants",
  "/sleep/recovery-wear/bakune-ladies-joggerpants-set":
    "/night-conditioning/recovery-wear/bakune-ladies-joggerpants-set",
  "/sleep/recovery-wear/bakune-ladies-sweat-shirt": "/night-conditioning/recovery-wear/bakune-ladies-sweat-shirt",
  "/sleep/recovery-wear/bakune-ladies-taperedpants": "/night-conditioning/recovery-wear/bakune-ladies-taperedpants",
  "/sleep/recovery-wear/bakune-ladies-taperedpants-set":
    "/night-conditioning/recovery-wear/bakune-ladies-taperedpants-set",
  "/sleep/recovery-wear/bakune-ladies23ss-jogger-pants":
    "/night-conditioning/recovery-wear/bakune-ladies23ss-jogger-pants",
  "/sleep/recovery-wear/bakune-ladies23ss-jogger-pants-set":
    "/night-conditioning/recovery-wear/bakune-ladies23ss-jogger-pants-set",
  "/sleep/recovery-wear/bakune-ladies23ss-sweat-shirt":
    "/night-conditioning/recovery-wear/bakune-ladies23ss-sweat-shirt",
  "/sleep/recovery-wear/bakune-ladies23ss-tunic": "/night-conditioning/recovery-wear/bakune-ladies23ss-tunic",
  "/sleep/recovery-wear/bakune-mesh-half-set": "/night-conditioning/recovery-wear/bakune-mesh-half-set",
  "/sleep/recovery-wear/bakune-mesh-half-t-shirt": "/night-conditioning/recovery-wear/bakune-mesh-half-t-shirt",
  "/sleep/recovery-wear/bakune-mesh-long-pants": "/night-conditioning/recovery-wear/bakune-mesh-long-pants",
  "/sleep/recovery-wear/bakune-mesh-long-set": "/night-conditioning/recovery-wear/bakune-mesh-long-set",
  "/sleep/recovery-wear/bakune-mesh-long-t-shirt": "/night-conditioning/recovery-wear/bakune-mesh-long-t-shirt",
  "/sleep/recovery-wear/bakune-mesh-short-pants": "/night-conditioning/recovery-wear/bakune-mesh-short-pants",
  "/sleep/recovery-wear/bakune-pajamas-cropped-pants": "/night-conditioning/recovery-wear/bakune-pajamas-cropped-pants",
  "/sleep/recovery-wear/bakune-pajamas-gauze-long-pants":
    "/night-conditioning/recovery-wear/bakune-pajamas-gauze-long-pants",
  "/sleep/recovery-wear/bakune-pajamas-gauze-set": "/night-conditioning/recovery-wear/bakune-pajamas-gauze-set",
  "/sleep/recovery-wear/bakune-pajamas-gauze-shirt": "/night-conditioning/recovery-wear/bakune-pajamas-gauze-shirt",
  "/sleep/recovery-wear/bakune-pajamas-half-set": "/night-conditioning/recovery-wear/bakune-pajamas-half-set",
  "/sleep/recovery-wear/bakune-pajamas-long-pants": "/night-conditioning/recovery-wear/bakune-pajamas-long-pants",
  "/sleep/recovery-wear/bakune-pajamas-long-set": "/night-conditioning/recovery-wear/bakune-pajamas-long-set",
  "/sleep/recovery-wear/bakune-pajamas-long-shirt": "/night-conditioning/recovery-wear/bakune-pajamas-long-shirt",
  "/sleep/recovery-wear/bakune-pajamas-premium": "/night-conditioning/recovery-wear/bakune-pajamas-premium",
  "/sleep/recovery-wear/bakune-pajamas-premium-pile": "/night-conditioning/recovery-wear/bakune-pajamas-premium-pile",
  "/sleep/recovery-wear/bakune-pajamas-shirt": "/night-conditioning/recovery-wear/bakune-pajamas-shirt",
  "/sleep/recovery-wear/bakune-pajamas-sweat-pants": "/night-conditioning/recovery-wear/bakune-pajamas-sweat-pants",
  "/sleep/recovery-wear/bakune-pajamas-sweat-set": "/night-conditioning/recovery-wear/bakune-pajamas-sweat-set",
  "/sleep/recovery-wear/bakune-pajamas-sweat-shirt": "/night-conditioning/recovery-wear/bakune-pajamas-sweat-shirt",
  "/sleep/recovery-wear/bakune-pajamas23ss-cropped-pants":
    "/night-conditioning/recovery-wear/bakune-pajamas23ss-cropped-pants",
  "/sleep/recovery-wear/bakune-pajamas23ss-half-set": "/night-conditioning/recovery-wear/bakune-pajamas23ss-half-set",
  "/sleep/recovery-wear/bakune-pajamas23ss-long-pants":
    "/night-conditioning/recovery-wear/bakune-pajamas23ss-long-pants",
  "/sleep/recovery-wear/bakune-pajamas23ss-long-set": "/night-conditioning/recovery-wear/bakune-pajamas23ss-long-set",
  "/sleep/recovery-wear/bakune-pajamas23ss-long-shirt":
    "/night-conditioning/recovery-wear/bakune-pajamas23ss-long-shirt",
  "/sleep/recovery-wear/bakune-pajamas23ss-shirt": "/night-conditioning/recovery-wear/bakune-pajamas23ss-shirt",
  "/sleep/recovery-wear/bakune-pajamas23ss-short-pants-set":
    "/night-conditioning/recovery-wear/bakune-pajamas23ss-short-pants-set",
  "/sleep/recovery-wear/bakune-premium-gown": "/night-conditioning/recovery-wear/bakune-premium-gown",
  "/sleep/recovery-wear/bakune-set": "/night-conditioning/recovery-wear/bakune-set",
  "/sleep/recovery-wear/bakune-sweat-pants": "/night-conditioning/recovery-wear/bakune-sweat-pants",
  "/sleep/recovery-wear/bakune-sweat-shirt": "/night-conditioning/recovery-wear/bakune-sweat-shirt",
  "/sleep/recovery-wear/bakune-warm-fleece-pants": "/night-conditioning/recovery-wear/bakune-warm-fleece-pants",
  "/sleep/recovery-wear/bakune-warm-fleece-shirt": "/night-conditioning/recovery-wear/bakune-warm-fleece-shirt",
  "/sleep/recovery-wear/bakune-warm-set": "/night-conditioning/recovery-wear/bakune-warm-set",
  "/sleep/recovery-wear/bakune-warm23fw-pants": "/night-conditioning/recovery-wear/bakune-warm23fw-pants",
  "/sleep/recovery-wear/bakune-warm23fw-set": "/night-conditioning/recovery-wear/bakune-warm23fw-set",
  "/sleep/recovery-wear/bakune-warm23fw-shirt": "/night-conditioning/recovery-wear/bakune-warm23fw-shirt",
  "/sleep/recovery-wear/bakune23fw-set": "/night-conditioning/recovery-wear/bakune23fw-set",
  "/sleep/recovery-wear/bakune23fw-sweat-pants": "/night-conditioning/recovery-wear/bakune23fw-sweat-pants",
  "/sleep/recovery-wear/bakune23fw-sweat-shirt": "/night-conditioning/recovery-wear/bakune23fw-sweat-shirt",
  "/sleep/recovery-wear/offline-bakune": "/night-conditioning/recovery-wear/offline-bakune",
  "/sleep/recovery-wear/offline-happybag2024-bakune": "/night-conditioning/recovery-wear/offline-happybag2024-bakune",
  "/sleep/sleep-accessory/bakune-blanket": "/night-conditioning/sleep-accessory/bakune-blanket",
  "/sleep/sleep-accessory/bakune-blanket24ss": "/night-conditioning/sleep-accessory/bakune-blanket24ss",
  "/sleep/sleep-accessory/bakune-dotz": "/night-conditioning/sleep-accessory/bakune-dotz",
  "/sleep/sleep-accessory/bakune-eye-mask": "/night-conditioning/sleep-accessory/bakune-eye-mask",
  "/sleep/sleep-accessory/bakune-haramaki": "/night-conditioning/sleep-accessory/bakune-haramaki",
  "/sleep/sleep-accessory/bakune-haramaki-dry": "/night-conditioning/sleep-accessory/bakune-haramaki-dry",
  "/sleep/sleep-accessory/bakune-neckwarmer": "/night-conditioning/sleep-accessory/bakune-neckwarmer",
  "/sleep/sleep-accessory/compression-socks": "/night-conditioning/sleep-accessory/compression-socks",
  "/sleep/sleep-accessory/compression-socks23fw": "/night-conditioning/sleep-accessory/compression-socks23fw",
  "/sleep/sleep-accessory/sleep-socks": "/night-conditioning/sleep-accessory/sleep-socks",
  "/sleep/sleep-accessory/sleep-socks23fw-long": "/night-conditioning/sleep-accessory/sleep-socks23fw-long",
  "/sleep/sleep-accessory/sleep-socks23fw-short": "/night-conditioning/sleep-accessory/sleep-socks23fw-short",
  "/work-support/bath-salt/totonou-bathsalt": "/night-conditioning/bath/totonou-bathsalt",
  "/work-support/mask/mask": "/day-conditioning/mask/mask",
  "/work-support/mask/mask-spray": "/day-conditioning/mask/mask-spray",
  "/work-support/mask/winter-mask": "/day-conditioning/mask/winter-mask",
  "/work-support/medical-scrub/medical-scrub-long-pants": "/day-conditioning/medical-scrub/medical-scrub-long-pants",
  "/work-support/medical-scrub/medical-scrub-set": "/day-conditioning/medical-scrub/medical-scrub-set",
  "/work-support/medical-scrub/medical-scrub-shirt": "/day-conditioning/medical-scrub/medical-scrub-shirt",
  "/work-support/protein/movon": "/day-conditioning/protein/movon",
  "/work-support/work-support-accessory/leg-warmer-long": "/day-conditioning/work-support-accessory/leg-warmer-long",
  "/work-support/work-support-accessory/recovery-leg-warmer":
    "/day-conditioning/work-support-accessory/recovery-leg-warmer",
  "/work-support/work-wear/body-care-inner": "/day-conditioning/inner-wear/body-care-inner",
  "/work-support/work-wear/body-care-inner-short-pants": "/day-conditioning/inner-wear/body-care-inner-short-pants",
  "/work-support/work-wear/body-care-inner-t-shirts": "/day-conditioning/inner-wear/body-care-inner-t-shirts",
  "/work-support/work-wear/hybrid-setup-nagie-tential":
    "/day-conditioning/comfort-tech-wear/hybrid-setup-nagie-tential",
  "/work-support/work-wear/migaru-dry-polo-shirts": "/day-conditioning/comfort-tech-wear/migaru-dry-polo-shirts",
  "/work-support/work-wear/migaru-dry-polo-shirts23ss":
    "/day-conditioning/comfort-tech-wear/migaru-dry-polo-shirts23ss",
  "/work-support/work-wear/migaru-dry-short-pants": "/day-conditioning/comfort-tech-wear/migaru-dry-short-pants",
  "/work-support/work-wear/migaru-dry-t-shirts": "/day-conditioning/comfort-tech-wear/migaru-dry-t-shirts",
  "/work-support/work-wear/migaru-dry-t-shirts-set": "/day-conditioning/comfort-tech-wear/migaru-dry-t-shirts-set",
  "/work-support/work-wear/migaru-inner-long-tights": "/day-conditioning/comfort-tech-wear/migaru-inner-long-tights",
  "/work-support/work-wear/migaru-inner-tshirt": "/day-conditioning/comfort-tech-wear/migaru-inner-tshirt",
  "/work-support/work-wear/migaru-innner-ladies-long-tights":
    "/day-conditioning/comfort-tech-wear/migaru-innner-ladies-long-tights",
  "/work-support/work-wear/migaru-jogger-pants": "/day-conditioning/comfort-tech-wear/migaru-jogger-pants",
  "/work-support/work-wear/migaru-ladies-inner-tshirt":
    "/day-conditioning/comfort-tech-wear/migaru-ladies-inner-tshirt",
  "/work-support/work-wear/migaru-one-piece": "/day-conditioning/comfort-tech-wear/migaru-one-piece",
  "/work-support/work-wear/migaru-pull-hoodie": "/day-conditioning/comfort-tech-wear/migaru-pull-hoodie",
  "/work-support/work-wear/migaru-sweat-shirt": "/day-conditioning/comfort-tech-wear/migaru-sweat-shirt",
  "/work-support/work-wear/migaru-work-wear-collarless-jacket":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-collarless-jacket",
  "/work-support/work-wear/migaru-work-wear-collarless-jacket-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-collarless-jacket-set",
  "/work-support/work-wear/migaru-work-wear-crewsweat23fw":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-crewsweat23fw",
  "/work-support/work-wear/migaru-work-wear-crewsweat23fw-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-crewsweat23fw-set",
  "/work-support/work-wear/migaru-work-wear-jogger-pants":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-jogger-pants",
  "/work-support/work-wear/migaru-work-wear-jogger-pants23fw":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-jogger-pants23fw",
  "/work-support/work-wear/migaru-work-wear-ladies-jogger-pants":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies-jogger-pants",
  "/work-support/work-wear/migaru-work-wear-ladies-onepiece23fw":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies-onepiece23fw",
  "/work-support/work-wear/migaru-work-wear-ladies-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies-set",
  "/work-support/work-wear/migaru-work-wear-ladies-swart-shirt":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies-swart-shirt",
  "/work-support/work-wear/migaru-work-wear-ladies-taperedpants23fw-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies-taperedpants23fw-set",
  "/work-support/work-wear/migaru-work-wear-ladies23fw-sweat-shirt":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies23fw-sweat-shirt",
  "/work-support/work-wear/migaru-work-wear-ladies23fw-taperedpants":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-ladies23fw-taperedpants",
  "/work-support/work-wear/migaru-work-wear-long-pants":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-long-pants",
  "/work-support/work-wear/migaru-work-wear-pull-hoodie":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-pull-hoodie",
  "/work-support/work-wear/migaru-work-wear-pull-hoodie-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-pull-hoodie-set",
  "/work-support/work-wear/migaru-work-wear-pull-hoodie23fw":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-pull-hoodie23fw",
  "/work-support/work-wear/migaru-work-wear-pull-hoodie23fw-set":
    "/day-conditioning/comfort-tech-wear/migaru-work-wear-pull-hoodie23fw-set",
  "/foot-wear": "/day-conditioning",
  "/foot-wear/foot-accessory": "/day-conditioning/work-support-accessory",
  "/foot-wear/insole": "/day-conditioning/insole",
  "/foot-wear/sandal": "/day-conditioning/sandal",
  "/foot-wear/sandal-copy": "/day-conditioning/sandal-copy",
  "/foot-wear/socks": "/day-conditioning/socks",
  "/sleep": "/night-conditioning",
  "/sleep/baby": "/night-conditioning/baby",
  "/sleep/bath": "/night-conditioning/bath",
  "/sleep/bedding": "/night-conditioning/bedding",
  "/sleep/giftset": "/night-conditioning/giftset",
  "/sleep/pillow": "/night-conditioning/pillow",
  "/sleep/recovery-wear": "/night-conditioning/recovery-wear",
  "/sleep/recovery-wear-copy": "/night-conditioning/recovery-wear-copy",
  "/sleep/sleep-accessory": "/night-conditioning/sleep-accessory",
  "/work-support": "/day-conditioning",
  "/work-support/bath-salt": "/night-conditioning/bath",
  "/work-support/mask": "/day-conditioning/mask",
  "/work-support/medical-scrub": "/day-conditioning/medical-scrub",
  "/work-support/protein": "/day-conditioning/protein",
  "/work-support/work-support-accessory": "/day-conditioning/work-support-accessory",
  "/work-support/work-wear": "/day-conditioning/comfort-tech-wear",
  "work-support/comfort-tech-wear": "/day-conditioning/comfort-tech-wear",
  "/philosophy": "/story",
  "/sleep/recovery_wear/general-medical-devices": "/general-medical-devices",
}
