import { request } from "graphql-request"
import { TOKEN_EXPIRED_MESSAGE } from "@tential/ec-gql-schema/const/error"
import { RenewalRedirectUrls } from "../const/renewal_redirect_url"
import useCookieState from "~/composables/useCookieState"
import { SignInTokenRefreshDocument, VerifyUserTokenDocument } from "~/gql/urql.generated"
import { useStore } from "~/stores"
import { RedirectUrls, RedirectUrl } from "~/const/redirect_url"
import { SignInTokenRefreshMutation, VerifyUserTokenMutation } from "~/types/type.generated"

const buildSignInPath = (fullPath: string): string => {
  if (fullPath.includes("sign_in")) {
    return `/sign_in`
  }
  return `/sign_in?next=${fullPath}`
}

const isValidRefreshToken = async (endpoint: string, refreshToken: string | undefined) => {
  if (!refreshToken) return
  try {
    const refreshRes = await request<SignInTokenRefreshMutation>(
      endpoint,
      SignInTokenRefreshDocument,
      {
        refreshToken,
      },
      {
        refreshToken,
        "x-graphql-client": "urql", // used in basic-auth
      },
    )
    return refreshRes.signInTokenRefresh
  } catch (e) {
    return undefined
  }
}

const isValidAccessToken = async (endpoint: string, accessToken: string, refreshToken: string | undefined) => {
  try {
    const data = await request<VerifyUserTokenMutation>(
      endpoint,
      VerifyUserTokenDocument,
      {},
      {
        custom_authorization: accessToken ? `Bearer ${accessToken}` : "",
        "x-graphql-client": "urql", // used in basic-auth
      },
    )
    if (!data?.verifyUserToken) throw new Error(TOKEN_EXPIRED_MESSAGE)

    return {
      isValid: true,
    }
  } catch (e) {
    if (e instanceof Error && e.message.includes(TOKEN_EXPIRED_MESSAGE)) {
      const user = await isValidRefreshToken(endpoint, refreshToken)
      return {
        isValid: !!user,
        user,
      }
    }
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  // リダイレクト処理
  const redirectUrl: RedirectUrl | undefined = RedirectUrls.find((el: RedirectUrl) => el.target_url === to.path)
  if (redirectUrl && redirectUrl.target_url) {
    const splitNum = to.fullPath.indexOf("?") + 1
    const queryString = splitNum > 0 ? to.fullPath.substring(splitNum - 1) : ""
    return navigateTo(redirectUrl.goto_url + queryString, { replace: true, redirectCode: 301 })
  }
  const config = useRuntimeConfig()

  const redirectTo = RenewalRedirectUrls[to.path] // リダイレクトマップからリダイレクト先を検索
  if (redirectTo) {
    const splitNum = to.fullPath.indexOf("?") + 1
    const queryString = splitNum > 0 ? to.fullPath.substring(splitNum - 1) : ""
    return navigateTo(redirectTo + queryString, { replace: true, redirectCode: 301 })
  }

  if (to.path.includes("healthcheck")) return

  const { getAccessToken, signOutCookie, getRefreshToken, setSignInCookie } = useCookieState()

  const store = useStore()
  const token = getAccessToken()

  if (!token) {
    if (store.user.isSigned) store.resetOut()

    if (to.path.includes("mypage") || to.path.includes("return_or_exchange") || to.path.includes("survey_question")) {
      // tokenがない状態でmypage配下に行こうとした場合はサインインページにリダイレクト
      return navigateTo(buildSignInPath(to.fullPath))
    }
    return
  }

  const res = await isValidAccessToken(config.public.GRAPHQL_ENDPOINT, token, getRefreshToken())
  if (res?.isValid) {
    if (res.user) {
      // 本来であればsetUserInfoToCookieAndStoreを使いたいがurqlを呼び出していることによる`setup()` or other lifecycle hooksエラーが出るため別々でセット
      setSignInCookie(res.user.access_token, res.user.refresh_token)
      await store.setUser({
        document_id: res.user.document_id,
        email: res.user.email,
        token: res.user.access_token,
        isSigned: true,
      })
    }
    return
  }
  signOutCookie()
  store.resetOut()
  if (to.path.includes("mypage") || to.path.includes("return_or_exchange") || to.path.includes("survey_question")) {
    return navigateTo(buildSignInPath(to.fullPath))
  }
})
