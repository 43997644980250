import {
  isToastedState,
  ToastedStateType,
  ToastedStateKey,
  RestockNoticeModalStateType,
  RestockNoticeModalStateKey,
  restockNoticeModalState,
} from "./sessionStorage"

// import { userProvider, UserProviderType, UserProviderKey } from "./user"
// import { adminProvider, AdminProviderType, AdminProviderKey } from "./admin"
// import {
//   HandleSubmitProviderKey,
//   HandleSubmitProviderType,
//   handleSubmitProvider,
// } from "~/utils/functions/admin_handle_submit"

// export const useHandleSubmitProvider = (): HandleSubmitProviderType | undefined => {
//   provide(HandleSubmitProviderKey, handleSubmitProvider())
//   return inject(HandleSubmitProviderKey)
// }

// export const defineUserProvider = (): void => {
//   return provide(UserProviderKey, userProvider())
// }

// export const useUserProvider = (): UserProviderType | undefined => {
//   return inject(UserProviderKey)
// }

// export const defineAdminProvider = (): void => {
//   return provide(AdminProviderKey, adminProvider())
// }

// export const useAdminProvider = (): AdminProviderType | undefined => {
//   return inject(AdminProviderKey)
// }

export const defineToast = (): void => {
  return provide(ToastedStateKey, isToastedState())
}

export const useToast = (): ToastedStateType | undefined => {
  return inject(ToastedStateKey)
}

export const defineRestockNoticeModal = (): void => {
  return provide(RestockNoticeModalStateKey, restockNoticeModalState())
}

export const useRestockNoticeModal = (): RestockNoticeModalStateType | undefined => {
  return inject(RestockNoticeModalStateKey)
}
