<template>
  <div>
    <div v-if="state.isSearchEngine">
      <NuxtLayout />
    </div>
    <div v-else>
      <ClientOnly>
        <NuxtLayout />
        <template #fallback>
          <OrganismsLoading />
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
const searchEngineBots = [
  "AdsBot-Google",
  "Google",
  "Googlebot",
  "Bingbot",
  "Slurp",
  "DuckDuckBot",
  "Baiduspider",
  "YandexBot",
  "facebookexternalhit",
  "bot",
]

const headers = useRequestHeaders(["user-agent"])
const userAgent = headers["user-agent"] || ""
const state: any = reactive({
  isSearchEngine: false,
})
const url = useRequestURL()

if (url.pathname === "/healthcheck") {
  state.isSearchEngine = true
} else {
  state.isSearchEngine = searchEngineBots.some((bot) => userAgent.includes(bot))
}
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
