export const isSkuOutOfStock = (sku: {
  stock_quantity: number
  stock_quantity_limit: number
  egift_stock_quantity_limit: number
  net_stock_quantity: number
  net_stock_quantity_limit: number
  is_reservation: boolean
}) => {
  const stock_quantity = sku.stock_quantity || 0
  const stock_quantity_limit = sku.stock_quantity_limit || 0
  const egift_stock_quantity = sku.egift_stock_quantity_limit || 0
  const net_stock_quantity = sku.net_stock_quantity || 0
  const net_stock_quantity_limit = sku.net_stock_quantity_limit || 0

  return (
    (!sku.is_reservation && stock_quantity === 0) ||
    (!sku.is_reservation && stock_quantity < stock_quantity_limit + egift_stock_quantity) ||
    (sku.is_reservation && net_stock_quantity < net_stock_quantity_limit)
  )
}
