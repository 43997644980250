const getPathName = (_path = ""): string => {
  return _path
    .replace(/\\/g, "/")
    .replace(/^[^/]*\/\/[^/]*/, "")
    .replace(/[?#].*?$/, "")
}

/**
 * gif, svg以外の画像パスを imgix などに変換する
 * @param appEnv アプリケーションごとの APP_ENV(development, production などが期待値)
 * @param _src 変換前の画像パス
 * @param _quality 画像の品質 (0.0 ~ 1.0)
 * @param _width 画像の幅 (default: 1280px)
 * @param _height 画像の高さ
 */
export const convertImagePath = (appEnv: string, _src = "", _quality = 1, _width = 1280, _height?: number): string => {
  // gifとsvgのときはそのまま返す
  if (~_src.indexOf(".gif") || ~_src.indexOf(".svg")) {
    return _src
  }
  if (~_src.indexOf("amazonaws.com")) {
    const path = getPathName(_src)
    let host = ""
    if (~_src.indexOf("-production")) {
      host = "kencoco-image.imgix.net"
    } else if (~_src.indexOf("kencoco-pro")) {
      host = "kencoco-s3-pro.imgix.net"
    } else if (~_src.indexOf("amazonaws.com") && ~_src.indexOf("tential-ec-pro")) {
      host = "tential-ec-pro.imgix.net"
    } else {
      return _src
    }
    _src = `https://${host}${path}?auto=compress&lossless=0&auto=format&q=${_quality * 100}&w=${_width * 1.8}`
    if (_height) _src += `&h=${_height * 1.8}`
  } else if ((appEnv === "production" || appEnv === "remote_development") && _src.charAt(0) === "/") {
    const containerDomain = appEnv === "remote_development" ? "tential-ec-dev" : "tential-ec-img"
    _src = `https://${containerDomain}.imgix.net${_src}?auto=compress&lossless=0&auto=format&q=${_quality * 100}&w=${
      _width * 1.8
    }`
    if (_height) _src += `&h=${_height * 1.8}`
  }
  return _src
}
