import VueScrollTo from "vue-scrollto"

export default defineNuxtPlugin((nuxtApp) => {
  // 使う
  nuxtApp.vueApp.use(VueScrollTo, {
    duration: 700,
    easing: [0, 0, 0.1, 1],
    offset: -100,
    lazy: false,
  })
})
