import { useConfirmDialogStore } from "~/stores/confirmDialog"

export const useConfirmDialog = () => {
  const store = useConfirmDialogStore()
  const proceed = (message: string): Promise<boolean> => {
    store.show(message)
    return new Promise((resolve) => {
      watch(
        () => store.isShow,
        (isShow) => {
          if (!isShow) resolve(store.isAgree)
        },
      )
    })
  }
  return { proceed }
}
